.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #14381e;
  gap: 30px;
}

.App-link {
  color: #14381e;
}

.App-link:hover {
  color: #833c19;
}

.c-loader {
  animation: is-rotating 2s infinite;
  border: 16px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #14381e;
  height: 250px;
  width: 250px;
}

@keyframes is-rotating {
  to {
    transform: rotate(2turn);
  }
}

/* .progress {
  height: 110px;
  width: 50vw;
  background-color: #9c9898;
  border-radius: 60px;
  position: relative;
}

.progress-bar {
  position: absolute;
  height: 100%;
  border-radius: 60px;
  background-color: #14381e;
  animation: progress-animation 6s infinite;
}

@keyframes progress-animation {
  0% {
    width: 20%;
  }

  100% {
    width: 100%;
  }
} */

.logo-silva {
  max-width: 40vw;
}
